type Item = {
  [x: string]: any;
  variants?: Array<{
    skuId?: string;
    sku?: string;
    activePrice?: number;
    price?: {
      centAmount?: number;
      fractionDigits?: number;
    };
    attributes?: {
      brand?: string;
    };
    picker?: {
      Size?: string;
      Color?: string;
    };
  }>;
  variant?: {
    sku?: string;
    attributes?: {
      brand?: string;
    };
    availability?: {
      totalQuantity?: number;
    };
    picker?: {
      Size?: string;
      Color?: string;
    };
  };
  productType?: string;
  productCategories?: string;
  id?: string;
  displayName?: string;
  SupplierId?: string;
  parentCategories?: string[];
  brands?: string;
  productId?: string;
  name?: string;
  title?: string;
  price?: {
    centAmount?: number;
    fractionDigits?: number;
  };
  categories?: Array<{
    name?: string;
    parent?: Array<{
      categoryId?: string;
      name?: string;
    }>;
  }>;
};

type Coupon = {
  name?: string;
};
type Variant = {
  attributes: {
    brand?: string;
  };
  brands?: string;
};

type Discount = {
  discountedAmount: {
    centAmount: number;
  };
  quantity: number;
};

type LineItem = {
  discounts?: Discount[];
  [key: string]: any;
};

type CartData = {
  displayLineItems?: Record<string, LineItem[]>;
  discount?: {
    centAmount: number;
    fractionDigits: number;
  };
};

type PromoDiscountResult = {
  discount: number;
};

export const transformItemData = (
  data: Item | Item[],
  coupon?: Coupon | string,
  price?: number,
  quantity?: number,
  variant?: Variant,
): any[] => {
  const inputArray = Array.isArray(data) ? data : [data];
  return inputArray.map((item, index) => {
    //pdp
    const categories = item?.categories ?? [];
    let modifiedCategories: Array<{ name?: string }> = [];
    if (categories.length > 0) {
      const parentCategories = categories[0]?.parent?.filter((parent) => parent?.name) || [];
      if (categories[0]?.parent) {
        modifiedCategories = [...parentCategories];
      }
      if (categories[0]?.name) {
        modifiedCategories.push({ name: categories[0]?.name });
      }
    }
    modifiedCategories = modifiedCategories.filter((category) => category.name?.toLowerCase() !== 'navigation tree');
    //you may also like
    const productTypeArray = item?.productType ? item.productType.split(',') : [];
    //cart- add to cart
    const categoriesAsString =
      Array.isArray(item?.productCategories) && item.productCategories.length > 0
        ? item.productCategories.join(', ')
        : typeof item?.productCategories === 'string' && item.productCategories !== 'undefined'
        ? item.productCategories
        : '';
    const productCategoriesArray = categoriesAsString ? categoriesAsString.split(',').filter(Boolean) : [];

    //clp & search
    const parentCategories = item?.parentCategories?.[0]?.split(' > ');

    const calculatedPrice =
      item?.price?.centAmount !== undefined
        ? item.price.centAmount / 10 ** (item.price.fractionDigits || 2)
        : item?.variants?.[0]?.activePrice ||
          item?.variants?.[0]?.price?.centAmount / 10 ** (item?.variants?.[0]?.price?.fractionDigits || 2) ||
          price;

    const calculateItemDiscount = (() => {
      const discount = item?.discounts?.[0];
      if (!discount || !discount?.discountedAmount || discount?.quantity == null) {
        return 0;
      }
      const { centAmount, fractionDigits = 2 } = discount?.discountedAmount;
      const quantity = discount?.quantity;
      return (centAmount * quantity) / 10 ** fractionDigits;
    })();

    //functions
    const getCategory = (index: number) =>
      modifiedCategories[index]?.name ||
      parentCategories?.[index] ||
      productCategoriesArray?.[index] ||
      productTypeArray[index] ||
      undefined;

    return {
      item_id:
        item?.variants?.[0]?.sku ||
        item?.variants?.[0]?.skuId ||
        item?.variant?.sku ||
        item?.sku ||
        item?.productId ||
        item?.id ||
        undefined,
      item_name: item?.title || item?.displayName || item?.name || undefined,
      item_variant: item?.itemGroupId || item?.productId || undefined,
      item_discount: calculateItemDiscount || 0,
      index: index,
      item_brand:
        item?.brands ||
        item?.variant?.attributes?.brand ||
        item?.variants?.[0]?.attributes?.brand ||
        item?.attributes?.brand ||
        variant?.attributes?.brand ||
        variant?.brands ||
        undefined,
      item_category: getCategory(0),
      item_category2: getCategory(1),
      item_category3: getCategory(2),
      item_category4: getCategory(3),
      item_category5: getCategory(4),
      affiliation: undefined,
      price: calculatedPrice || item?.price || undefined,
      coupon: coupon ?? ((typeof coupon === 'object' ? coupon?.name : coupon) || item?.discountTexts?.[0] || undefined),
      quantity: quantity || item?.count || 1,
      size: item?.variant?.picker?.Size || item?.variants?.[0]?.picker?.Size,
      color: item?.variant?.picker?.Color || item?.variants?.[0]?.picker?.Color,
    };
  });
};

export const transformedDataGift = (data: Item | Item[], price?: number, quantity?: number, base?: any): any[] => {
  const inputArray = Array.isArray(data) ? data : [data];
  return inputArray.map((item, index) => {
    const calculatedPrice =
      item?.price?.centAmount !== undefined
        ? item.price.centAmount / 10 ** (item.price.fractionDigits || 2)
        : item?.variants?.[0]?.activePrice ||
          item?.variants?.[0]?.price?.centAmount / 10 ** (item?.variants?.[0]?.price?.fractionDigits || 2) ||
          price;
    return {
      item_id: item?.sku || undefined,
      item_name: base?.name || item?.name || undefined,
      item_variant: item?.[index]?.sku || item?.sku || undefined,
      item_discount: undefined,
      index: index || 0,
      item_brand: item?.[index]?.attributes?.brand || item?.attributes?.brand || undefined,
      item_category: base?.categories?.[0]?.parent?.[1]?.name || undefined,
      item_category2: base?.categories?.[0]?.name || undefined,
      item_category3: undefined,
      item_category4: undefined,
      affiliation: undefined,
      price: calculatedPrice || price || undefined,
      coupon: undefined,
      quantity: quantity ?? item?.lineItems?.count ?? 1,
    };
  });
};

export const calculateDiscount = (cartData: CartData): PromoDiscountResult => {
  let promoDiscount = 0;
  const filteredDiscountedItems = cartData?.displayLineItems
    ? Object.values(cartData.displayLineItems).map((lineItems: LineItem[]) => {
        const tempFilteredDiscountedItems: LineItem[] = [];
        const shippingAddressKeys = Object.keys(lineItems);
        for (const key of shippingAddressKeys) {
          if (Array.isArray(lineItems[key])) {
            const filteredItems = lineItems[key].filter((lineItem: LineItem) => lineItem?.discounts?.length > 0);
            tempFilteredDiscountedItems.push(...filteredItems);
          }
        }
        return tempFilteredDiscountedItems;
      })
    : [];
  const discountedItems = filteredDiscountedItems.flat();
  discountedItems.forEach((item) => {
    item?.discounts?.forEach((discount) => {
      promoDiscount += discount?.discountedAmount?.centAmount * discount?.quantity;
    });
  });
  const amount = cartData?.discount?.centAmount + (promoDiscount > 0 && promoDiscount);
  const discount = amount / 10 ** (cartData?.discount?.fractionDigits || 2);
  return {
    discount,
  };
};

export function generateFilterPath(array: any[], targetName: string, refinementName: string) {
  const result = array?.find((item) => item?.name == targetName);
  if (!result || !result.displayName || !refinementName) {
    return undefined;
  }
  return `${result.displayName} > ${refinementName}`;
}

export const calculateTotalValue = (items: any[]): number => {
  return parseFloat(
    items
      .reduce((acc, item) => {
        const price = item.price || 0;
        const qty = item.quantity || 1;
        return acc + price * qty;
      }, 0)
      .toFixed(2),
  );
};

export function isAAFESownedSite(link) {
  if (
    link?.includes('myecp') ||
    link?.includes('aafes') ||
    link?.includes('publicaffairs') ||
    link?.includes('business-delivery') ||
    link?.includes('shopmyexchange') ||
    link?.includes('applymyexchange') ||
    link?.includes('/cp') ||
    link?.includes('exchangepost') ||
    link?.includes('/deals') ||
    link?.includes('clearance') ||
    link?.includes('Outlet')
  ) {
    return true;
  }
  return false;
}
