import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import useSWR from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export interface DeleteAccountModalProps {
  ctaCallback?: () => void;
  closeModal?: () => void;
}

function DeleteAccountModal({ closeModal, ctaCallback = () => {} }: DeleteAccountModalProps) {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const [accountDeletedMsg, setAccountDeletedMsg] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const queryParams = new URLSearchParams({
    messageKey: 'accountDeletedMsg',
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  useEffect(() => {
    if (data) {
      setAccountDeletedMsg(data);
      setShowModal(true);
    }
  }, [data]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      ctaCallback();
    }
  };
  useEffect(() => {
    document.documentElement.style.overflow = '';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, []);
  if (!showModal) return null;
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 z-[100] max-h-[700px] w-[350px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] py-6 dark:bg-primary-200 md:mt-9 md:w-[420px] lg:mt-3 lg:w-[460px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section className="modalHeader relative">
                        <div className="mt-5 mb-6">
                          <h3 className="text-[18px] font-semibold text-black-900">
                            {formatAccountMessage({
                              id: 'accountDeleted',
                              defaultMessage: 'User Account has been deleted',
                            })}
                          </h3>
                          <button
                            className="absolute right-0 top-2 outline-none focus:outline-none"
                            onClick={closeModal}
                          >
                            <GrClose />
                          </button>
                        </div>
                      </section>
                      <section>
                        <p className="mb-8 text-affes-sm font-bold">{accountDeletedMsg}</p>
                      </section>
                      <button
                        type="button"
                        id="signinbtn"
                        onClick={closeModal}
                        onKeyDown={handleKeyDown}
                        className="block w-32 rounded-sm bg-blue-900 py-2 text-affes-xl font-bold text-white"
                      >
                        {formatAccountMessage({ id: 'ok', defaultMessage: 'Ok' })}
                      </button>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default DeleteAccountModal;
