import { useCallback } from 'react';
import {
  GA_CONTINUE_TO_MARKETPLACE,
  GA_SELECT_ITEM,
  GA_SERVER_URL,
  GA_VIEW_CART,
  GA_VIEW_ITEM,
  GA_VIEW_ITEM_LIST,
  GA_VIEW_CLP,
  EXTPRICE_BRONZE,
  GA_TYPE_YOU_MAY_LIKE,
  GTM_WISHLIST,
  GA_TYPE_SAVED_FOR_LATER,
  GA_TYPE_PRODUCT_CATEGORY,
  GA_GIFT_CARDS,
} from 'helpers/constants/aafes';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { transformItemData, transformedDataGift } from 'helpers/utils/commonGAUtils';
import { priceInUSD } from 'helpers/utils/priceConverter';
import useGATrackUserAction from './useGATrackUserAction';

interface UserProperties {
  cid: string;
  segmentType: string;
  milstat: string;
  region: string;
  gender: string;
  myStore: string;
  timeZone: string;
  age: number;
}

interface GlobalParamUserProperties {
  'user_properties.age'?: number;
  'user_properties.cid'?: string;
  'user_properties.gender'?: string;
  'user_properties.milstat'?: string;
  'user_properties.region'?: string;
  'user_properties.segmentType'?: string;
  'user_properties.timeZone'?: string;
  'user_properties.myStore'?: string;
}
export const getUserProperties = (account: any, calculateAge: (birthday: string) => number): UserProperties => {
  return {
    cid: account?.cid || '',
    segmentType: account?.branchOfServiceDescription || '',
    milstat: account?.militaryStatus || '',
    region: 'US',
    timeZone: 'CST',
    gender: account?.gender || '',
    myStore: account?.defaultStore?.name || '',
    age: calculateAge(account?.birthday || ''),
  };
};
export const getGlobalParamUserProperties = (
  account: any,
  calculateAge: (birthday: string) => number,
): GlobalParamUserProperties => {
  return {
    'user_properties.age': calculateAge(account?.birthday) || undefined,
    'user_properties.cid': account?.cid || undefined,
    'user_properties.gender': account?.gender || undefined,
    'user_properties.milstat': account?.militaryStatus || undefined,
    'user_properties.region': 'US',
    'user_properties.segmentType': account?.branchOfServiceDescription || undefined,
    'user_properties.timeZone': 'CST',
    'user_properties.myStore': account?.defaultStore?.name || undefined,
  };
};
export const setCategoryValues = (product: any) => {
  const categories = product?.categories ?? [];
  const parentCategories = categories?.[0]?.parent?.length > 0 ? categories?.[0]?.parent : [];
  const modifiedCategories =
    categories?.length > 0
      ? [...parentCategories, categories?.[0]]?.filter((category) => category?.name !== 'Navigation Tree')
      : [];
  return modifiedCategories;
};

const useGATrackViewActions = () => {
  const { calculateAge } = useGATrackUserAction();
  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const getSeverUrl = (): string => {
    return window.location.host;
  };

  const trackDataLayerInitialization = useCallback((eventName, pageType, siteSections, account: any) => {
    const userProperties = getGlobalParamUserProperties(account, calculateAge);
    if (siteSections?.length && siteSections[0] === 'Navigation Tree') {
      siteSections.shift();
    }
    try {
      const pageView = {
        content_group: pageType || undefined,
        content_group2: siteSections?.length > 0 ? siteSections[0] : undefined,
        content_group3: siteSections?.length > 1 ? siteSections[1] : undefined,
        content_group4: siteSections?.length > 2 ? siteSections[2] : undefined,
        content_group5: siteSections?.length > 3 ? siteSections[3] : undefined,
        ...userProperties,
      };

      logEventToDataLayer(eventName, pageView);
    } catch (error) {
      console.error('dataLayer_ready pageview event fail', error);
    }
  }, []);

  const trackViewItem = useCallback((trackViewItemObj: any) => {
    const { product, type } = trackViewItemObj;
    try {
      const items = transformItemData(product);
      const viewItem = {
        ecommerce: {
          currency: product?.price?.currencyCode,
          value: product?.price?.centAmount / 10 ** product?.price?.fractionDigits,
          items: items,
        },
      };
      logEventToDataLayer(GA_VIEW_ITEM, viewItem);
    } catch (error) {
      console.error('ga4 view item event fail', error);
    }
  }, []);

  const trackViewCart = useCallback((trackViewCartObj: any) => {
    const { cart, contentId } = trackViewCartObj;
    try {
      const transformedItem = transformItemData(cart?.lineItems);
      const viewCart = {
        content_id: contentId,
        ecommerce: {
          currency: cart?.subTotal?.currencyCode || EXTPRICE_BRONZE.currencyCode,
          value: cart?.subTotal?.centAmount / 10 ** cart?.subTotal?.fractionDigits,
          items: transformedItem,
        },
      };
      logEventToDataLayer(GA_VIEW_CART, viewCart);
    } catch (error) {
      console.error('ga4 view cart event fail', error);
    }
  }, []);

  const trackViewItemList = useCallback((trackViewItemListObj: any) => {
    const { res, type, base } = trackViewItemListObj;
    try {
      const dataArray = Array.isArray(res) ? res : Array.isArray(res?.records) ? res.records : [];
      const transformedItem =
        type === GA_GIFT_CARDS ? transformedDataGift(res, undefined, undefined, base) : transformItemData(dataArray);

      const viewItemList = {
        ecommerce: {
          currency: EXTPRICE_BRONZE.currencyCode,
          item_list_name: type,
          items: transformedItem,
        },
      };
      if (!res && !(type === GA_TYPE_YOU_MAY_LIKE)) {
        logEventToDataLayer(GA_VIEW_CLP, viewItemList);
      } else {
        logEventToDataLayer(GA_VIEW_ITEM_LIST, viewItemList);
      }
    } catch (error) {
      console.error('ga4 view_item_list event fail', error);
    }
  }, []);

  const trackSelectItem = useCallback((trackSelectItemObj: any) => {
    const { product, type, productDroplets, price, variant, base } = trackSelectItemObj;
    try {
      const coupon = productDroplets?.promotions?.length ? productDroplets.promotions : undefined;
      const transformedItem =
        type === GA_GIFT_CARDS
          ? transformedDataGift(product, undefined, undefined, base)
          : transformItemData(product, undefined, price, undefined, variant);
      const selectItem = {
        ecommerce: {
          currency: EXTPRICE_BRONZE.currencyCode,
          item_list_name: type,
          items: transformedItem,
        },
      };
      if (type === GTM_WISHLIST || type === GA_TYPE_SAVED_FOR_LATER || type === GA_TYPE_PRODUCT_CATEGORY) {
        delete selectItem?.ecommerce?.currency;
        logEventToDataLayer(GA_SELECT_ITEM, selectItem);
      } else {
        logEventToDataLayer(GA_SELECT_ITEM, selectItem);
      }
    } catch (error) {
      console.error('ga4 select_Item event fail', error);
    }
  }, []);

  const viewItemListTracker = useCallback((trackListItemObj: any) => {
    const { product, type, price } = trackListItemObj;
    try {
      const items = transformItemData(product);

      items.forEach((item, index) => {
        const matchingPrice = price?.[product[index]?.lineItemId]?.extPrice;
        if (matchingPrice) {
          item.price = priceInUSD(matchingPrice);
        }
      });
      const listItem = {
        ecommerce: {
          item_list_name: type,
          items: items,
        },
      };
      logEventToDataLayer(GA_VIEW_ITEM_LIST, listItem);
    } catch (error) {
      console.error('ga4 view_item_list event fail', error);
    }
  }, []);

  return {
    trackViewItem,
    trackViewCart,
    getUserProperties,
    trackViewItemList,
    trackSelectItem,
    setCategoryValues,
    viewItemListTracker,
    trackDataLayerInitialization,
  };
};

export default useGATrackViewActions;
