import { Component, ErrorInfo, ReactNode } from 'react';
import { GTM_RUNTIME_JS_EXCEPTION } from 'helpers/constants/aafes';

interface Props {
  children: ReactNode;
  trackErrorPage: (error: any) => void;
}

interface State {
  hasError: boolean;
  errorLogged?: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorLogged: false,
  };

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log the error in GTM
    if (this.state.hasError && !this.state.errorLogged) {
      this.props.trackErrorPage(GTM_RUNTIME_JS_EXCEPTION);
      this.setState({ errorLogged: true });
    }

    // You can use your own error logging service here
    // TODO
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <p>Check the console for details.</p>
          <button type="button" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
