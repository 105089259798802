import { fetchApiHub } from 'frontastic';
import { AttributeFilter } from '@Types/query/AttributeFilter';
import { BUNDLE_ITEM } from 'helpers/constants/aafes';
import { PromotionProducts } from '@Types/talonone/Talonone';
import { VariantAltImage } from '@Types/product/Variant';
export const searchProduct = async (query, pageSize) => {
  const payload = {
    query,
    pageSize,
  };
  const res = await fetchApiHub(
    '/action/product/searchProduct',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const autoSuggestSearch = async (searchTerm) => {
  const payload = {
    searchTerm: encodeURIComponent(searchTerm),
  };
  const res = await fetchApiHub(
    '/action/productSearch/autoSuggestSearch',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const selectAvailableUniforms = async (gender: string, branchOfServiceDescription: string) => {
  const payload = [
    {
      type: 'string',
      name: 'gender',
      value: gender,
    },
    {
      type: 'string',
      name: 'branchesOfService',
      value: branchOfServiceDescription,
    },
    {
      type: 'string',
      name: 'customProductType',
      value: BUNDLE_ITEM,
    },
  ];
  const res = await fetchApiHub(
    '/action/product/getProductsByAttributes',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const fetchProductsByProductGroups = async (productGroups: AttributeFilter[]) => {
  try {
    const payload = [...productGroups];
    const res = await fetchApiHub(
      '/action/product/getProductsByAttributes',
      {
        method: 'POST',
      },
      payload,
    );
    return res;
  } catch (error) {
    console.error('Error while fetching product by product groups:', error);
    return {
      count: 0,
    };
  }
};

export const getSkusExternalPrices = async (skus: string[]) => {
  const res = await fetchApiHub(`/action/product/getSkusExternalPrices?skus=${skus?.join(',')}`);
  return res;
};

export const marketPlaceDetails = async (sku: string) => {
  const payload = {
    sku,
  };
  const res = await fetchApiHub(
    '/action/marketplace/marketplaceDetails',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};
export const getMarketingBugProducts = async (skus: string) => {
  const res = await fetchApiHub(`/action/product/search?query=${skus}`, {
    method: 'POST',
  });
  return res;
};

export const getShipToAddressInventoriesAvailability = async (skus: string[]) => {
  const payload = {
    productId: skus,
  };
  const res = await fetchApiHub(
    `/action/mao/getShipToAddressInventoriesAvailability`,
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const getDroplets = async (payload: PromotionProducts[]) => {
  const res = await fetchApiHub(
    `/action/promotions/getDroplets`,
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

/**
 * This function retrieves alternative images for a product based on its SKU.
 * @param {string} sku - The `sku` parameter in the `getAltImages` function is a string.
 * @param {AbortSignal} signal - The `signal` parameter in the `getAltImages` function is an
 * `AbortSignal` object.It can be used to signal that an ongoing fetch operation should
 * be aborted, typically in scenarios
 * @returns If the `altImages` array has a length greater than 0, the function will return the
 * `altImages` array as an array of `VariantAltImage` objects. Otherwise, if the `altImages` array is
 * empty or null, the function will return null.
 */
export const getAltImages = async (sku: string, signal: AbortSignal) => {
  const res = await fetchApiHub(`/action/product/getProductAltImagesBySku?sku=${sku}`, { signal });
  const altImages = res?.altImages;
  if (altImages?.length > 0) {
    return altImages as VariantAltImage[];
  } else {
    return null;
  }
};
