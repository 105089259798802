declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Initialize the dataLayer if it doesn't exist
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

// Function to log events directly to the dataLayer
export const logEventToDataLayer = (eventName: string, eventParams: Record<string, any> = {}) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  }
};
