import { useCallback } from 'react';
import {
  GA_APPLY_FILTER,
  GA_ERROR_PAGE,
  GA_FORM_COMPLETE,
  GA_SELECT_CONTENT,
  GA_SELECT_PROMOTION,
  GA_SORT,
  GA_VIEW_PROMOTION,
  GA_VIEW_SEARCH_RESULTS,
} from 'helpers/constants/aafes';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { getUserProperties } from './useGATrackViewActions';

const useGATrackUserAction = () => {
  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const trackUserAction = useCallback((eventName, account: any) => {
    // const gtag = window?.gtag;
    try {
      const userAccountAction = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
      };
      // gtag('event', eventName, userAccountAction);
    } catch (error) {
      console.error('ga4 userAccountAction event fail', error);
    }
  }, []);

  const trackSearchResult = useCallback((trackSearchObj: any) => {
    const { contentId, searchTerm, searchResults } = trackSearchObj;
    try {
      const searchResult = {
        content_id: contentId,
        search_term: searchTerm,
        search_results: searchResults,
      };
      logEventToDataLayer(GA_VIEW_SEARCH_RESULTS, searchResult);
    } catch (error) {
      console.error('ga4 view search result event fail', error);
    }
  }, []);

  const trackApplyFilter = useCallback((trackFilterObj: any) => {
    const { contentId, filterTerm, filterPath, contentType } = trackFilterObj;
    try {
      const filterObj: any = {
        content_id: contentId,
        filter_term: filterTerm,
      };
      if (contentType) filterObj.content_type = contentType;
      if (filterPath) filterObj.filter_path = filterPath;
      logEventToDataLayer(GA_APPLY_FILTER, filterObj);
    } catch (error) {
      console.error('ga4 apply filter event fail', error);
    }
  }, []);

  const trackSort = useCallback((trackSortObj: any) => {
    const { contentId, sortTerm } = trackSortObj;
    try {
      const sortObj = {
        content_id: contentId,
        sort_term: sortTerm,
      };
      logEventToDataLayer(GA_SORT, sortObj);
    } catch (error) {
      console.error('ga4 sort event fail', error);
    }
  }, []);

  const trackSelectItem = useCallback((trackSelectObj: any) => {
    const { contentId } = trackSelectObj;
    try {
      const selectObj = {
        content_id: contentId,
      };
      logEventToDataLayer(GA_SELECT_CONTENT, selectObj);
    } catch (error) {
      console.error('ga4 select item event fail', error);
    }
  }, []);

  const trackViewPromotion = useCallback((trackPromoObj: any) => {
    const { contentId, promoName, creativeName, creativeSlot } = trackPromoObj;
    try {
      const promotionObj = {
        content_id: contentId,
        promotion_name: promoName,
        creative_name: creativeName,
        creative_slot: creativeSlot,
      };
      logEventToDataLayer(GA_VIEW_PROMOTION, promotionObj);
    } catch (error) {
      console.error('ga4 view promotion event fail', error);
    }
  }, []);

  const trackSelectPromotion = useCallback((trackPromoObj: any) => {
    const { contentId, promoName, creativeName, creativeSlot } = trackPromoObj;
    try {
      const promotionObj = {
        content_id: contentId,
        promotion_name: promoName,
        creative_name: creativeName,
        creative_slot: creativeSlot,
      };
      logEventToDataLayer(GA_SELECT_PROMOTION, promotionObj);
    } catch (error) {
      console.error('ga4 select promotion event fail', error);
    }
  }, []);

  const trackErrorPage = useCallback((errorCode) => {
    try {
      const errorpageData = {
        content_group2: errorCode,
      };
      logEventToDataLayer(GA_ERROR_PAGE, errorpageData);
    } catch (error) {
      console.error('ga4 error page event fail', error);
    }
  }, []);

  const trackFormComplete = useCallback((trackFormObj) => {
    const { contentId, formName } = trackFormObj;
    try {
      const formObj = {
        content_id: contentId,
        form_name: formName,
      };
      logEventToDataLayer(GA_FORM_COMPLETE, formObj);
    } catch (error) {
      console.error('ga4 form complete event fail', error);
    }
  }, []);

  function calculateAge(birthdayString) {
    if (!birthdayString) {
      return null;
    }
    const dateString = birthdayString.split('T')[0];
    const [year, month, day] = dateString.split('-');
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    if (isNaN(birthDate.getTime())) {
      return null;
    }
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return {
    trackUserAction,
    calculateAge,
    trackSearchResult,
    trackApplyFilter,
    trackSort,
    trackSelectItem,
    trackViewPromotion,
    trackSelectPromotion,
    trackErrorPage,
    trackFormComplete,
  };
};

export default useGATrackUserAction;
