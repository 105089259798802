import { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { GA_PAGE_VIEW_DATALAYER_READY, GA_PAGE_VIEW } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { useAccount } from 'frontastic';
const GTMinitiator = ({ pageProps }) => {
  const gtm_id = process?.env?.NEXT_PUBLIC_GTM_ID;
  const router = useRouter();
  const { account } = useAccount();
  const { trackDataLayerInitialization } = useGATrackViewActions();
  const { trackErrorPage } = useGATrackUserAction();
  const getContentNames = useCallback(() => {
    const selectedFilter = pageProps?.data?.data?.dataSources?.__master?.selectedFilters;
    if (selectedFilter) {
      const categories =
        selectedFilter
          .map((filters) => filters.refinements)
          .flat()
          .map((refinements) => refinements.name) || [];
      return categories;
    }
    const categories = pageProps?.data?.data?.dataSources?.__master?.product?.categories?.[0];
    const parentCategoryNames = categories?.parent?.map((parent) => parent.name);
    if (categories?.name) {
      parentCategoryNames?.push(categories.name);
    }
    return parentCategoryNames;
  }, [pageProps]);

  function formatSegment(segment) {
    return segment
      .replace(/-/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function getPageNameFromPath() {
    const path = window.location.pathname;
    const segments = path.split('/');
    const pageName = segments.filter((segment) => segment.trim() !== '').pop();
    const formattedPageName = formatSegment(pageName || '');
    return `Shop Army &amp; Air Force Exchange Service - ${formattedPageName}`;
  }

  useEffect(() => {
    const pageTitle = document.title ? document.title : getPageNameFromPath();
    const { asPath } = router;
    const frontasticPageFolderType = pageProps?.data?.pageFolder?.pageFolderType;
    const isMarketplaceProduct = pageProps?.data?.data?.dataSources?.__master?.product?.isMarketplaceProduct || false;
    let computedPageType = 'other';
    if (pageTitle === 'Page Not Found' || pageTitle === 'Internal Server Error') {
      computedPageType = 'error_page';
    } else {
      switch (true) {
        case asPath === '/':
          computedPageType = 'homepage';
          break;
        case asPath.includes('query'):
          computedPageType = 'search results';
          break;
        case asPath.includes('pageCategories'):
          computedPageType = 'product list';
          break;
        case asPath.includes('/marketplace'):
          computedPageType = 'marketplace';
          break;
        case frontasticPageFolderType?.includes('product-detail-page') && isMarketplaceProduct:
          computedPageType = 'marketplace product details';
          break;
        case frontasticPageFolderType?.includes('product-detail-page'):
          computedPageType = 'product details';
          break;
        case asPath.includes('/cart'):
          computedPageType = 'cart';
          break;
        case asPath.includes('/checkout'):
          computedPageType = 'checkout';
          break;
        case asPath.includes('/register'):
          computedPageType = 'user registration';
          break;
        case asPath.includes('/account#orders'):
          computedPageType = 'my account orders';
          break;
        case asPath.includes('/account#subscription'):
          computedPageType = 'my account subscription';
          break;
        case asPath.includes('/account#wallet'):
          computedPageType = 'my account wallet';
          break;
        case asPath.includes('/account#addresses'):
          computedPageType = 'my account addresses';
          break;
        case asPath.includes('/account#wishlist'):
          computedPageType = 'my account wishlist';
          break;
        case asPath.includes('/account#profile'):
          computedPageType = 'my account profile';
          break;
        case asPath.includes('/account#communicationPreference'):
          computedPageType = 'my account communication preference';
          break;
        case asPath.includes('/account#'):
          computedPageType = 'my account';
          break;

        default:
          break;
      }
    }

    //console.log('pageType:', computedPageType);
    trackDataLayerInitialization(GA_PAGE_VIEW_DATALAYER_READY, computedPageType, getContentNames(), account);
    logEventToDataLayer(GA_PAGE_VIEW);
    //in error boundary page---  "run time exception" as content_group2
    if (pageTitle === 'Page Not Found') {
      trackErrorPage(404);
    } else if (pageTitle === 'Internal Server Error') {
      trackErrorPage(500);
    }
  }, [router?.asPath]);

  useEffect(() => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s) as HTMLScriptElement,
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtm_id);
  }, [gtm_id]);

  return null;
};

export default GTMinitiator;
